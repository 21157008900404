.scrollorizzontale {
    overflow-x: hidden;
    overflow-y: auto;
}

.schedaSottotitolo {
    font-variant: small-caps;
    font-weight: bold; 
    color: #ea8500;
    /* color: #0090ea; */
    /* border-bottom: 1px solid #009efd; */
    border-bottom: 1.2px solid #ea8500; 
    margin-top: 15px;
    margin-bottom: 15px;
    font-size: 1.3em;
}

.interrogativo {
    height: 16px;
    width: 16px;
    margin-left: 5px;
    cursor: pointer
}

.titoloTabella {
    text-transform: uppercase;
    color: #f0f0f0;
    font-weight: bold; 
    font-size: 1rem;    
    background-color: #5386a7;
    border-right: 1px solid #e0e0e0;
}

.schedaContenitore {
    display: flex;
    flex-direction: column;
}

.schedaSpazioCentrale {
    background-color:#F2F2F2;
    flex:1;
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
    overflow-y:auto; 
    overflow-x:hidden;
    display:'flex';
    flex-direction: 'row';
}

.schedaSpazioInferiore {
    padding-left: 10px; 
    padding-Bottom: 10px;
    border-top: 1px solid #404040;
    background-color: #e0e0e0;
}

.spazioFinestraDialogo {
    display: flex;
    padding-top: 50px;
    justify-content: center;
}

.larghezzaFinestraDialogo {
    width: 50%;
    max-width: 600px;
    background-color:#f0f0f0;
    border: 1px solid #a0a0a0
}

.titoloTabellaReport {
    font-weight: bold;
    background-color: rgb(159, 223, 255);
}

.rigaTitoloTabellaReport {
    border: 1px solid #a0a0a0
}

.rigaTabellaReport {
    border-bottom: 1px solid #a0a0a0
}

.homeWidget {
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.evidenziatoAllarme {
    background-color: #D00000;
    color: white;
    padding-left: 5px;
    padding-right: 5px;
}

.evidenziatoAvviso {
    background-color: #ffcc5e;
    color: #000000;
    padding-left: 5px;
    padding-right: 5px;
}

.evidenziatoNormale {
    background-color: #f0f0f0;
    color: #000000;
    padding-left: 5px;
    padding-right: 5px;
}

.sfondoRigaAllarme {
    background-color: #A00000;      
}

.carattereRigaAllarme {
    color: #F0F0F0;
}

.sfondoRigaAvviso {
    background-color: #ffcc5e;
}

.carattereRigaAvviso {
    color: #000000;
}

.sfondoRigaPari {
    background-color: #fcfcfc;
}

.sfondoRigaDispari {
    background-color: #f0f0f0;
}   

.carattereRigaNormale {
    color: #000000;
}

.puntatoreMano {
    cursor: pointer;
}

.LinearProgressWithLabelVerdeGrigio > * { background-color:rgb(41, 151, 41) !important; }
.LinearProgressWithLabelVerdeGrigio {background-color:lightgray !important;}

.LinearProgressWithLabelGialloGrigio > * { background-color:#ffcc5e !important; }
.LinearProgressWithLabelGialloGrigio {background-color:lightgray !important;}

.contenitoreLoginAvvisiIniziali {
    height: 100vh;
    width: 99.9wh;
    background-color: #C0C0C0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.logo {
    height: 100px; /* L'altezza predefinita */
}

@media (max-width: 600px) {
    .logo {
        width: 80%; /* Imposta una larghezza massima dell'80% dello schermo */
        height: auto; /* Mantiene le proporzioni dell'immagine */
    }
}

.larghezzaCardLoginAvvisiIniziali {
    width: 60%;
    max-width: 600px;
}

@keyframes lampeggiaPuntini {
    0%, 100% { opacity: 1; }
    50% { opacity: 0; }
}
