/* generale */

@font-face {
    font-family: 'PaytoneOne-Regular';
    src: url('../fonts/PaytoneOne-Regular.woff2') format('woff2'),
         url('../fonts/PaytoneOne-Regular.woff') format('woff'),
         url('../fonts/PaytoneOne-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

.contenitoreBody {
    width: 100%;
    background-color: #2370a2;
}

.testoNormaleCentrato {    
    font-size: 18px;
    text-align: center;
    color: #bbddff;
}

.testoPiccoloCentrato {    
    font-size: 15px;
    text-align: center;
    color: #bbddff;
}

.testoPiccoloCentratoSottolineato {    
    font-size: 15px;
    text-align: center;
    color: #bbddff;
    border-bottom: 1px solid #bbddff;
}

.testoPiccolo {    
    font-size: 15px;
    color: #bbddff;
}

.casellaInput {
    border: 1px solid #034072;
    height: 40px;
    padding: 10px;
    color: #002244;
    background-color: #d0d0d0;
    font-size: 18px;
}

.casellaInputGenerica {
    font-size: 18px;
    background-color: #e0e0e0;
    color: #202020;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 5px;
    border: 1px solid #000;
}

.genericaDropdown {    
    background-color: #e0e0e0;
    border: 1px solid #000;
    font-size: 18px;
    color: #202020;
    width: 100%;
}

.genericaDropdownBianca {    
    background-color: #ffffff;
    border: 1px solid #000;
    font-size: 18px;
    color: #202020;
    width: 100%;
}

.genericaDropdownTesto {
    font-size: 18px;
    color: #202020;
    padding-left: 5px;
    padding-top: 5px;
    padding-bottom: 5px;
}

.genericaDropdownTestoTendina {
    font-size: 18px;
    color: #000;
}

.genericaDropdownAreaTendina {
    border: 1px solid #fff;
    background-color: #707070;
    margin-left: -1px;
    margin-right: -1px;
}

.alignEnd {
    display: flex;
    justify-content: flex-end;
}

.flexRow {
    display: flex;
    flex-direction: row;
}

/* bottoni */
.Bottone, .BottoneAltezzaDoppia {
    background-color: #002244;
    width: 140px;
    height: 140px;
    border-radius: 70px;
    justify-content: center;
    border: 2px solid #6688aa;
    /* bottone in native aveva transform: [{scaleY:0.35},{translateY:-0}], */
}

.BottoneAltezzaDoppia {
    transform: scaleY(0.45);
}

.Bottone2 {
    background-color: #002244;
    width: 110px;
    height: 45px;
    border-radius: 20px;
    justify-content: center;
}

.testoBottone, .testoBottoneAltezzaDoppia {
    position: absolute;
    width: 140px;
    font-size: 18px;
    text-align: center;
    color: #bbddff;
}

.testoBottone {
    top: 56px;
}

.testoBottoneAltezzaDoppia {
    top: 46px;
}

/* bottoni app-wide */
.bottone3, .bottone4, .bottone5, .bottone6 {
    display: flex;
    flex-direction: column;
    border: 1px solid;
    margin-left: 3px;
    margin-right: 3px;
    margin-top: 5px;
    align-items: center;
    cursor: pointer;
}

.bottone3 {
    flex: 1;
    height: 50px;
}

.bottone4, .bottone5, .bottone6 {
    padding-left: 10px;
    padding-right: 10px;
}

.bottone5 {
    padding-top: 5px;
    padding-bottom: 5px;
    flex: 1;
}

.bottone6 {
    padding-top: 5px;
    padding-bottom: 5px;
}

.bottoneColoreNormale {
    border-color: #608080;
    background-color: #AFCFCF;
}

.bottoneColoreRosso {
    border-color: #FF0000;
    background-color: #FFA0A0;
}

.bottoneColoreVerde {
    border-color: #008000;
    background-color: #A0D0A0;
}

.bottoneColoreGrigio {
    border-color: #808080;
    background-color: #AFAFAF;
}

.bottoneTestoNormale {
    font-size: 16px;
    color: #404040;
}

.bottoneTestoBold {
    font-size: 16px;
    color: #404040;
    font-weight: bold;
}

/* icone */
.iconaPiccolaQuadrata, .iconaPiccolaRettangolare, .iconaPiccolaAltezzaFissa, .iconaPiccolaLarghezzaFissa {
    object-fit: contain;
}

.iconaPiccolaQuadrata {
    width: 25px;
    height: 25px;
}

.iconaPiccolaRettangolare {
    width: 50px;
    height: 25px;
}

.iconaPiccolaAltezzaFissa {
    height: 25px;
}

.iconaPiccolaLarghezzaFissa {
    width: 25px;
}

.spazioIconaPiccola {
    display: flex;
    align-items: center;
}

.iconaMenuHome {
    object-fit: contain;
    width: 75px;
    height: 50px;
}

.testoIconaMenuHome, .testoIconaMenuHome2 {
    color: #E0E0E0;
    font-size: 18px;
    text-align: center;
    margin: 0;
    font-family: 'PaytoneOne-Regular', sans-serif;
    white-space: pre-line; /* gestisce l'andata a capo con \n nel testo */
}

.testoIconaMenuHome {
    background-color: #2370a2;
}

.testoIconaMenuHome2 {
    background-color: #A00000;
}

.spazioIconaMenuEsterno {
    flex: 1;
    background-color: #E0E0E0;
    margin: 5px;
    border: 2px solid #E0E0E0;
    text-align: center;
    padding-left: 5px;
    padding-right: 5px;    
}

.spazioIconaMenuInterno {
    align-items: center;
    margin-top: 10px;
    margin-bottom: 5px;
}

/* menu a tendina */
.menutendina {
    border: 1.5px solid rgb(179, 97, 97);
    background-color: #c4c4b0;
    position: absolute;
    top: 40px;
    right: 5%;
    z-index: 10;
}

.vocemenutendina {
    text-align: center;
    width: 100px;
    padding-top: 9px;
    padding-bottom: 9px;
    font-size: 20px;
}

.vocemenutendina_bordo_basso {
    /* border-bottom: 1px solid rgb(179, 97, 97); */
}

/* barra del titolo */
.barraTitoloSpazioIcona {
    justify-content: center;
    align-content: center;
    background-color: #53a0d2;
}

.barraTitoloSpazioNomePersona {
    flex: 1;
    justify-content: center;
    background-color: #53a0d2;
}

.barraTitoloSpazioTitolo {
    position: relative;    
    display: flex;
    align-items: center;
    position: relative;
    justify-content: space-between;
    width:100%;
    height: 25px;
    background-color: #f0d070;
}

.barraTitoloNomePersona {
    text-align: center;
    color: #fff;
    font-size: 20px;
    text-shadow: 2px 2px #2370a2;
}

.barraTitoloTestoTitolo {
    text-align: center;
    color: #606060;
    font-size: 18px;
    font-family: 'PaytoneOne-Regular', sans-serif;
}

/* scelta identità */
.listaIdentita {
    margin-left: 30px;
    margin-right: 30px;
    justify-content: center;
    background-color: #e0e0e0;
}
.titoloListaIdentita {
    font-size: 20px;
    text-align: center;
    color: #004040;
    background-color: #f0d080;
    border-bottom-width: 1px;
    border-bottom-color: #202020;
}
.elementoListaIdentita {
    width: 100%;
    border-bottom-width: 1px;
    border-bottom-color: #202020;
    align-content: center;
}
.nomeElementoListaIdentita {
    text-align: center;
    font-size: 20px;
    color: #400000;
    margin-top: 10px;
}
.tesseraElementoListaIdentita {
    text-align: center;
    font-size: 14px;
    color: #303030;
    margin-bottom: 4px;
}
.societaElementoListaIdentita {
    text-align: center;
    font-size: 16px;
    color: #003030;
    margin-bottom: 10px;
}

/* eventi */
.eventiSpazioSottotitolo {
    justify-content: center;
    margin-top: 2px;
    margin-bottom: 5px;
}
.eventiTestoSottotitolo {
    text-align: center;
    color: #ffffff;
    font-size: 18px;
    font-family: 'PaytoneOne-Regular', sans-serif;
}
.eventiElencoContenitoreElemento {
    background-color: #e0e0e0;
    padding: 10px;
    margin-bottom: 2px;
}
.eventiElencoTestoData {
    font-style: italic;
    font-size: 16px;
    margin-left: 5px;
    color: #202020;
}
.eventiElencoTestoDescrizione {
    font-size: 18px;
    margin-left: 5px;
    color: #202020;
}
.eventiElencoTestoLuogo {
    font-weight: bold;
    font-size: 18px;
    margin-left: 5px;
    color: #202020;
}
.eventiElencoIndicatoreMsgNonLetti {
    position: relative;
    top: -45px;
    right: -30px;
    width: 25px;
    height: 20px;
    font-size: 12px;
    padding-top: 1px;
    border-radius: 20px;
    background-color: #b00000;
    color: #e0e0e0;
    text-align: center;
}
.eventiIconaTastieraUp {
    object-fit: contain;
    width: 25px;
}
.eventiIconaTastiera {
    object-fit: contain;
    height: 25px;
}
.eventiModificaTestoTitoli {
    font-size: 16px;
    color: #ffffff;
}
.eventiModificaInputSpazio {
    flex: 1;
}
.eventiModificaInputTesto {
    font-size: 18px;
    background-color: #e0e0e0;
    color: #202020;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 5px;
}
.eventiModificaInputSelect {
    background-color: #e0e0e0;
}

.eventiCopiaDropdown {
    background-color: #e0e0e0;
    margin-left: 10px;
    margin-right: 10px;
}
.eventiCopiaDropdownTesto {
    font-size: 18px;
    color: #202020;
    padding-left: 5px;
}

.eventiChatContenitoreInviaA {
    display: flex;
    flex-direction: row;    
    background-color: #c0c0c0;
}
.eventiChatDidascaliaInviaA {
    padding-left: 5px;
    padding-right: 5px;
    justify-content: center;
}
.eventiChatBottoneInviaStaff, .eventiChatBottoneInviaTutti {
    flex: 1;
    justify-content: center;
    align-items: center;
}
.eventiChatDropDownDestinatari {
    flex: 1;
    justify-content: center;
    align-items: center;
}
.eventiChatDropDownContenitoreElementi {
    background-color: #e0e0e0;
}
.eventiChatDropDownTestoElementi {
    font-size: 16px;
    color: #606060;
}

.eventiChatTestoBottoneInviaTuttiSelezionato, .eventiChatTestoBottoneInviaStaffSelezionato, .eventiChatTestoBottoneInviaSingoloSelezionato {
    color: #000000;
    background-color: #a0ffa0;
    border-color: #000000;
    border-width: 1px;
    border-style: solid;
    min-width: 60%;
    text-align: center;
    font-weight: bold;
    font-size: 16px;
}
.eventiChatTestoBottoneInviaTuttiSelezionato {
    background-color: #a0ffa0;
}
.eventiChatTestoBottoneInviaStaffSelezionato {
    background-color: #7fffd4;
}
.eventiChatTestoBottoneInviaSingoloSelezionato {
    background-color: #ffcccc;
}

.eventiChatContenitoreScrivi {
    display: flex;
    flex-direction: row;
    background-color: #000000;
    padding-bottom: 10px; /* spazio nero in basso per eventuale barra dei browser, verificare se è sufficiente */
    min-height: 40px;
}
.eventiChatTestoScrivi {
    flex: 1;
    font-size: 18px;
    color: #000000;
    background-color: #e0e0e0;
}
.eventiChatTestoScriviA {
    color: #000000;
    font-size: 16px;
}
.eventiChatSpazioIconaInviaStaff, .eventiChatSpazioIconaInviaTutti, .eventiChatSpazioIconaInviaSingolo {
    padding-left: 15px;
    padding-right: 15px;
    border-color: #202020;
    border-width: 1px;
    border-style: solid;
    justify-content: center;
    align-items: center;
}
.eventiChatSpazioIconaInviaStaff {
    background-color: #7fffd4;
}
.eventiChatSpazioIconaInviaTutti {
    background-color: #a0ffa0;
}
.eventiChatSpazioIconaInviaSingolo {
    background-color: #ffcccc;
}
.eventiChatIconaInvia {
    object-fit: contain;
    height: 30px;
    width: 30px;
}
.eventiChatTestoInvia {
    color: #202020;
}

.eventiChatBollaAltriATutti, .eventiChatBollaAltriAStaff, .eventiChatBollaAltriAMe, .eventiChatBollaMiaATutti, .eventiChatBollaMiaAStaff, .eventiChatBollaMiaAUno {
    width: fit-content;              /* Adatta la larghezza alla dimensione del contenuto */
    max-width: 85%;                  /* Evita che si estenda oltre l'85% in caso di messaggi molto lunghi */
    margin-top: 3px;
    margin-bottom: 3px;
    padding-left: 4px;
    padding-right: 4px;
    padding-top: 2px;
    padding-bottom: 2px;
    border-radius: 8px;
    display: flex;              /* Usa flexbox per adattare il contenuto */
    flex-direction: column;      /* Imposta il testo e l’orario in colonne */
    align-items: flex-start;     /* Allinea il contenuto a sinistra */
    box-sizing: border-box;      /* Assicura che padding sia incluso in max-width */
    word-wrap: break-word;
}

.eventiChatBollaAltriATutti, .eventiChatBollaAltriAStaff, .eventiChatBollaAltriAMe {
    margin-left: 10px;
}

.eventiChatBollaMiaATutti, .eventiChatBollaMiaAStaff, .eventiChatBollaMiaAUno {
    margin-right: 10px;
}

.eventiChatBollaAltriATutti {
    background-color: #f0f0f0;
}
.eventiChatBollaAltriAStaff {
    background-color: #7fffd4;
}
.eventiChatBollaAltriAMe {
    background-color: #ffcccc;
}
.eventiChatBollaMiaATutti {
    background-color: #ccffee;
}
.eventiChatBollaMiaAStaff {
    background-color: #7fffd4;
}
.eventiChatBollaMiaAUno {
    background-color: #ffcccc;
}
.eventiChatTestoBollaMittente {
    color: #a01010;
    font-size: 16px;
}
.eventiChatTestoBollaTesto {
    color: #404040;
    font-size: 16px;
}
.eventiChatTestoBollaDataOra {
    color: #606060;
    font-style: italic;
    font-size: 13px;
    white-space: nowrap; /* L'orario rimane su una riga */
    align-self: flex-end;        /* Posiziona l’orario a destra all'interno della bolla */
    margin-top: 4px;             /* Spazio tra testo e orario */
    padding-left: 10px;
}

/* Spazio titolo convocati */
.eventiSpazioTitoloConvocati {
    display: flex;
    flex-direction: row;
}

/* Testo titolo convocati staff */
.eventiTestoTitoloConvocatiStaff {
    flex: 1;
    padding-left: 5px;
    padding-top: 5px;
    font-size: 16px;
    color: #E0E0E0;
}

/* Convocati ScrollView */
.eventiConvocatiScrollView {
    background-color: #E0E0E0;
}

/* Spazio elemento convocati */
.eventiConvocatiSpazioElemento {
    display: flex;
    flex-direction: row;
    background-color: #D0E0E0;
    border-bottom: 0.8px solid #B0B0B0;
    padding-top: 8px;
    padding-bottom: 2px;
    padding-left: 6px;
}

/* Spazio titolo convocati */
.eventiConvocatiSpazioTitolo {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #B0B0B0;
    padding-top: 4px;
    padding-bottom: 4px;
}

/* Nome convocati */
.eventiConvocatiNome {
    flex: 3;
    color: #404080;
    font-size: 16px;
}

/* Titolo nome convocati */
.eventiConvocatiTitoloNome {
    flex: 3;
    border: 1px solid;
    margin-left: 3px;
    margin-right: 3px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    text-align: center;
}

/* Spazio icona titolo convocati */
.eventiConvocatiSpazioTitoloIcona {
    flex: 1;
    text-align: center;
}

/* Icona titolo convocati */
.eventiConvocatiTitoloIcona {
    color: #E0E0E0;
    font-size: 16px;
}

/* Spazio elemento risultati convocati */
.eventiConvocatiSpazioElementoRisultati {
    display: flex;
    flex-direction: column;
    background-color: #D0E0E0;
    padding-top: 8px;
    padding-bottom: 2px;
    padding-left: 5px;
    padding-right: 5px;
}

/* Bottone aggiungi/rimuovi */
.eventiBottoneAggiungiRimuovi {
    background-color: #D0D0D0;
    margin-left: 2px;
    margin-top: 5px;
    padding-left: 5px;
    padding-right: 5px;
    font-size: 16px;
    border: 1px solid #000000;
}

/* Bottoni in basso */
.eventiBottoniInBasso {
    flex: 1;
    background-color: #D0D0D0;
    margin-left: 1px;
    margin-right: 1px;
    margin-top: 5px;
    height: 42px;
    display: flex;
    justify-content: center;
    border: 1px solid;
}

/* Testo bottoni in basso */
.eventiTestoBottoniInBasso {
    padding-left: 5px;
    padding-right: 5px;
    font-size: 16px;
    text-align: center;
}

/* Spazio elemento convocare */
.eventiConvocareSpazioElemento {
    display: flex;
    flex-direction: row;
    background-color: #D0D0D0;
    border-bottom: 0.8px solid #B0B0B0;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 6px;
}

/* Spazio elemento convocare selezionato */
.eventiConvocareSpazioElementoSelezionato {
    display: flex;
    flex-direction: row;
    background-color: #D0FFFF;
    border-bottom: 0.8px solid #B0B0B0;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 6px;
}

/* Spazio elemento convocare selezionato negativo */
.eventiConvocareSpazioElementoSelezionatoNegativo {
    display: flex;
    flex-direction: row;
    background-color: #FFD0D0;
    border-bottom: 0.8px solid #B0B0B0;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 6px;
}

/* Icona cerchio */
.eventiIconaCerchio {
    object-fit: contain;
    width: 20px;
    height: 20px;
    margin-right: 6px;
}

/* Esercizi */
.eserciziImmagine {
    object-fit: contain;
    width: 200px;
    height: 200px;
}

.eserciziSpazioImmagine {
    width: 200px;
    height: 200px;
}

.eserciziInputTesto {
    font-size: 18px;
    background-color: #e0e0e0;
    color: #202020;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 5px;
    border: 1px solid #000000;
}

.eserciziInputData {
    font-size: 18px;
    background-color: #e0e0e0;
    color: #000000;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 5px;
    border: 1px solid #000000;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.eserciziModificaNumeroEsercizio {
    font-weight: bold;
    font-size: 18px;
    color: #202020;
}

.eserciziModificaTestoTitoli {
    font-size: 16px;
    color: #202020;
}

/* Elenco schede allenamento ed elenco esercizi ed elenco news */
.elencoSchedeContenitoreElemento {
    display: flex;
    flex-direction: row;
    background-color: #E0E0E0;
    padding: 10px;
    margin-bottom: 5px;
}

.elencoSchedeContenitoreElementoSelezionato {
    display: flex;
    flex-direction: row;
    background-color: #D0FFFF;
    padding: 10px;
    margin-bottom: 5px;
}

.elencoSchedeContenitoreElementoEvidenziato {
    display: flex;
    flex-direction: row;
    background-color: #FFD0D0;
    padding: 10px;
    margin-bottom: 5px;
}

.elencoSchedeTesto {
    font-size: 18px;
    color: #202020;
}

.elencoSchedeNome {
    font-size: 18px;
    font-weight: bold;
    color: #202020;
    margin:0; /* aggiunto su react, va messo in tutti gli elementi <p> di un elenco */
}

.elencoSchedeDateAssegnazione {
    font-size: 18px;
    color: #202020;
}

.elencoSchedeDateCreazione {
    font-size: 16px;
    font-style: italic;
    color: #202020;
}

/* Schede allenamento */
.schedaAllenamentoSpazioImmagineEsercizio {
    border: 1px solid #606060;
    width: 202px;
    height: 202px;
}

.schedaAllenamentoImmagineEsercizio {
    object-fit: contain;
    width: 200px;
    height: 200px;
}

.schedaAllenamentoSpazioImmagineEsercizioFull {
    position: relative;
    border: 1px solid #606060;
    width: 100%;
    height: 100%;
}

.schedaAllenamentoImmagineEsercizioFull {
    object-fit: contain;
    position: relative;
    width: 100%;
    height: 100%;
}

.schedaAllenamentoTestoTitoli {
    padding-left: 5px;
    font-size: 20px;
    color: #1952fd;
    background-color: #afe6f9;
    
}

.schedaAllenamentoTestoDati {
    padding-left: 5px;
    font-size: 22px;
    color: #202020;
}

.schedaAllenamentoTestoClasse {
    font-style: italic;
    padding-left: 5px;
    font-size: 18px;
    color: #202020;
}

.schedaAllenamentoTestoNumeroEsercizio {
    padding-left: 5px;
    font-size: 20px;
    color: #202020;
    text-align: center;
    font-weight: bold;
}

/* Scadenze */
.scadenzeTestoNomePersona {
    font-size: 16px;
    color: #FFFFFF;
}

.scadenzeElencoContenitoreElemento {
    background-color: #E0E0E0;
    padding: 10px;
    margin-bottom: 5px;
}

.scadenzeElencoTestoTitolo {
    text-align: center;
    font-size: 22px;
    color: #000040;
    margin-top: 10px;
}

.scadenzeElencoTestoNormale {
    text-align: center;
    font-size: 20px;
    color: #FFFFFF;
    font-weight: bold;
    background-color: #009000;
    margin-bottom: 10px;
}

.scadenzeElencoTestoAllarme {
    text-align: center;
    font-size: 20px;
    color: #FFFFFF;
    font-weight: bold;
    background-color: #FF0000;
    margin-bottom: 10px;
}

/* Movimenti */
.movimentiTestoNomePersona {
    font-size: 16px;
    color: #FFFFFF;
}

.movimentiElencoContenitoreElemento {
    background-color: #E0E0E0;
    padding: 10px;
    margin-bottom: 2px;
}

.movimentiTestoData {
    text-align: center;
    font-size: 18px;
    color: #FFFFFF;
    background-color: #707070;
}

.movimentiTestoImportoPositivo {
    text-align: center;
    font-size: 18px;
    color: #FFFFFF;
    background-color: #409040;
}

.movimentiTestoImportoNegativo {
    text-align: center;
    font-size: 18px;
    color: #FFFFFF;
    background-color: #C94848;
}

.movimentiTestoOperazione {
    text-align: center;
    font-size: 18px;
}

.movimentiSpazioTitoloDettagli {
    display: flex;
    justify-content: center;
    margin-top: 2px;
    margin-bottom: 5px;
}

.movimentiTestoTitoloDettagli {
    text-align: center;
    color: #FFFFFF;
    font-size: 18px;
    font-family: 'PaytoneOne-Regular', sans-serif;
}

.movimentiBottoneScaricaRicevuta {
    display: flex;
    justify-content: center;
    margin-top: 2px;
    margin-bottom: 5px;
    text-align: center;
    border-color: #608080;
    background-color: #AFCFCF;
    color: #608080;
    font-size: 18px;  
    cursor: pointer;  
}

.prenotazioniElemento {
    width: 100%;
    height: 50px;
    margin-top: 15px;
    margin-bottom: 0;
    text-align: center;
    font-size: 24px;
    color: var(--coloreTestoCliccabile); /* Sostituisci con il valore specifico se necessario */
    border-bottom: 1px solid #202020;
}

.prenotazioniviewContenitoreTitolo {
    display: flex;
    flex-direction: row;
    background-color: #FFFFDD;
}

.prenotazioniviewFreccia {
    /* Nessuno stile specificato */
}

.prenotazioniviewTitoloNomeMese {
    flex: 1;
    display: flex;
    justify-content: center;
}

.prenotazionitestoFreccia {
    padding-bottom: 0;
    padding-top: 3px;
    margin: 0;
    border-bottom-width: 0;
    border-top-width: 0;
    font-size: 30px;
    text-align: center;
    color: var(--coloreTestoCliccabile); /* Sostituisci con il valore specifico se necessario */
}

.prenotazionitestoNomeMeseTitolo {
    padding-bottom: 0;
    padding-top: 3px;
    margin: 0;
    border-bottom-width: 0;
    border-top-width: 0;
    font-size: 18px;
    text-align: center;
    color: #606060;
}

.prenotazioniviewContenitoreStrisciaCalendario {
    background-color: #FFFFDD;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: row;
    border-bottom: 0.9px solid; /* Specificare il colore se necessario */
}

.prenotazioniviewGiornoSettimana {
    flex: 0.15;
}

.prenotazionitestoNomeGiorno {
    padding-bottom: 4px;
    padding-top: 9px;
    margin: 0;
    border-bottom-width: 0;
    border-top-width: 0;
    font-size: 15px;
    text-align: center;
    color: #606060;
}

.prenotazionitestoNumeroGiorno {
    padding-bottom: 0;
    padding-top: 0;
    margin: 0;
    border-bottom-width: 0;
    border-top-width: 0;
    font-size: 22px;
    text-align: center;
    color: var(--coloreTestoCliccabile); /* Sostituisci con il valore specifico se necessario */
}

.prenotazionitestoNumeroGiornoDisabilitato {
    padding-bottom: 0;
    padding-top: 0;
    margin: 0;
    border-bottom-width: 0;
    border-top-width: 0;
    font-size: 22px;
    text-align: center;
    color: #606060;
}

.prenotazionitestoNumeroGiornoSelezionato {
    padding-bottom: 0;
    padding-top: 0;
    margin: 0;
    border-width: 0;
    font-size: 22px;
    text-align: center;
    color: #F0F0F0;
    background-color: #448888;
    border-radius: 18px;
}

.prenotazionitestoNomeMese {
    padding-bottom: 9px;
    padding-top: 4px;
    margin: 0;
    border-bottom-width: 0;
    border-top-width: 0;
    font-size: 15px;
    text-align: center;
    color: #606060;
}

.prenotazionitestoGiornoSelezionato {
    padding-bottom: 0;
    padding-top: 3px;
    margin: 0;
    border-bottom-width: 0;
    border-top-width: 0;
    font-size: 18px;
    text-align: center;
    color: #DDDDDD;
}
