.contenitoreLogin {
    height: 100vh;
    width: 99.9wh;
    background-color: #C0C0C0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.logo {
    height: 100px; /* L'altezza predefinita */
}

@media (max-width: 600px) {
    .logo {
        width: 80%; /* Imposta una larghezza massima dell'80% dello schermo */
        height: auto; /* Mantiene le proporzioni dell'immagine */
    }
}

.larghezzaCardLogin {
    width: 60%;
    max-width: 600px;
}